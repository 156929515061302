/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"PEV Ústí nad Labem - Naše práce"}>
        <Column className="--center pb--60 pt--60" style={{"backgroundColor":"rgba(114,152,228,1)"}} name={"[[UNIsecname1]]"} parallax={false}>
          
          <ColumnWrap className="column__flex --left el--1 flex--bottom" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"2"} animS={"3"}>
              
              <Title className="title-box title-box--right fs--62 w--900" style={{"maxWidth":804}} content={"Naše práce:"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l9"} name={"538jkaqnr5b"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37602/ba78c4a0fe0e433ba5358259dfa521e3_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37602/ba78c4a0fe0e433ba5358259dfa521e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37602/ba78c4a0fe0e433ba5358259dfa521e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37602/ba78c4a0fe0e433ba5358259dfa521e3_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37602/3ee4d1f054be472fa58e802b950b13c7_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37602/3ee4d1f054be472fa58e802b950b13c7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37602/3ee4d1f054be472fa58e802b950b13c7_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37602/33a7cbdb81af45bb8490f4ea0d6f919e_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37602/33a7cbdb81af45bb8490f4ea0d6f919e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37602/33a7cbdb81af45bb8490f4ea0d6f919e_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37602/30ad57d60daf47eb84fd75475fa455c5_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37602/30ad57d60daf47eb84fd75475fa455c5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37602/30ad57d60daf47eb84fd75475fa455c5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37602/30ad57d60daf47eb84fd75475fa455c5_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"[[UNIgenericsecname2]]"}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":1200}} columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="css-17zetwm pb--80 pt--80" name={"[[UNIsectionname3]]"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Neváhejte nás kontaktovat:<br></span><br>"}>
              </Title>

              <Button className="btn-box" content={"Kontaktujte nás"} url={"https://pevusti.cz/kontakt"} href={"https://pevusti.cz/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}